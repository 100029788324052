import React from 'react';
import {Link} from 'gatsby';
import {OutboundLink} from 'gatsby-plugin-google-gtag';

import Layout from '../../components/Layout';
import Footer from '../../components/Footer';
import SignUpCTA from '../../components/SignUpCTA';
import Nav from '../../components/Nav';
import AboutPackageContainer from '../../components/AboutPackageContainer';
import Paragraph from '../../ui/Paragraph';
import StandardPricePackage from '../../components/PricePackages/StandardPricePackage';
import EnvData from '../../components/EnvData';
import {PRIMARY_COLOR_2} from '../../constants';
import {aboutBasicRoute, aboutPremiumRoute} from '../../routes';
import useEnvContext from '../../hooks/useEnvContext';

export default function StandardPage() {
	return (
		<EnvData>
			<ThePage />
		</EnvData>
	);
}

const ThePage = function () {
	const {signUpRoute} = useEnvContext();

	return (
		<Layout title="STANDARD paket usluga" description="Saznajte detalje o STANDARD paketu usluga, što točno nudi i koje su cijene">
			<Nav background={PRIMARY_COLOR_2} />

			<AboutPackageContainer color1="#cd5077" color2="#931247">
				<aside>
					<StandardPricePackage />
				</aside>
				<main>
					<h1>STANDARD paket</h1>
					<h2 className="mt-2">Sve is BASIC paketa</h2>
					<Paragraph>Ovaj paket sadržava sve mogućnosti što i BASIC paket, plus slijedeće:</Paragraph>
					<h2>Mogućnost prijevoda svih elemenata cjenika na jezike koje odaberete</h2>
					<Paragraph>
						Unutar postavki cjenika, označite jezike na koje želite prevesti svoj cjenik nakon čega će vam se, pri uređivanju svakog
						elementa cjenika, pojaviti polje za unos teksta za druge jezike. Prevesti možete apsolutno sve; sve naslove, podtekstove, nazive
						stavki, njihov opis, količine, itd.
					</Paragraph>
					<Paragraph>
						Kada vaš gost ili kupac skenira vaš QR kȏd, naš sustav će pokušati razlučiti na koji jezik je postavljen uređaj vašeg
						gosta/kupca. Ako je Vaš cjenik preveden na detektirani jezik, cjenik će se automatski i prikazati na tom jeziku bez da gost mora
						mijenjati ikakve postavke. U slučaju da nema podudaranja jezika ili se jezik nije uspješno detektirao, prikazat će se cjenik na
						hrvatskom.
					</Paragraph>
					<Paragraph>
						Kod višejezičnih cjenika, vašim gostima će se u gornjem lijevom kutu stranice pojaviti zastava jezika na kojem je trenutno
						cjenik prikazan. Pritiskom na zastavu, cjenik se može prebaciti na neki drugi prevedeni jezik.
					</Paragraph>
					<h2>Mogućnost dodavanja suradnika</h2>
					<Paragraph>
						Dodavanjem suradnika možete dopustiti drugoj osobi pristup vašim cjenicima. Ovo rješava nekoliko potencijalnih slučajeva:
					</Paragraph>
					<ul>
						<li>
							Ako Vi nemate vremena baviti se uređivanjem cjenika, ali želite svom osoblju ili, npr. dizajneru, dati mogućnost pristupa,
							onda ih dodajte pod suradnike.
						</li>
						<li>Ako postoji više vlasnika za isto uslužno mjesto, onda možete dodati suradnika s ravnopravnim pristupom.</li>
					</ul>
					<Paragraph>
						Prava pristupa su podjeljena na tri razine. Razine su detaljno opisane u administracijskom sučelju u postavkama pod{' '}
						<em>Suradnici</em>.
					</Paragraph>
					<h2>Mogućnost ubacivanja neograničenog broja tekstova</h2>
					<Paragraph>U BASIC paketu je moguće ubaciti dva proizvoljna teksta. U STANDARD paketu to ograničenje ne postoji.</Paragraph>
					<h2>Mogućnost odabira domene cjenika</h2>
					<Paragraph>
						Ovo vam omogućava da se vaš cjenik nalazi na jednoj od <em>cool</em> domena koje su ponuđene u postavkama u administracijskom
						sučelju. Trenutno u ponudi imamo slijedeće cool domene:
					</Paragraph>
					<ul>
						<li>cjenik.today</li>
						<li>danasnji.menu</li>
						<li>najbolji.bar</li>
						<li>najbolji.pub</li>
						<li>najbolja.pizza</li>
					</ul>
					<Paragraph>
						Npr., ako vodite svoj pub, odaberite domenu <strong>najbolji.pub</strong>! Pogledajte primjer kako bi izgledao link prema našem{' '}
						<a href="https://danasnji.menu/splendid-restoran/433.200.543">demo restoranu</a> kada bi se link sherao na Facebooku:
					</Paragraph>
					<div className="img-max-500">
						<img src="/images/fb-restoran-share.jpg" alt="Facebook dijeljenje primjer" />
					</div>
					<h2 className="mt-2">Cijena</h2>
					<Paragraph>
						Ako plaćate iz mjeseca u mjesec, cijena ovog paketa je 13,25€, a ako platite za cijelu godinu unaprijed, onda je cijena 132,50€
						što je ekvivalent od 11,04€/mj. Sve cijene su izražene s uključenim PDV-om. Plaćanje se uvijek radi za period koji slijedi, tako
						da ugovorna obveza ne postoji.
					</Paragraph>
					<nav>
						<Link to={aboutBasicRoute()}>&larr; BASIC paket</Link>
						<OutboundLink href={signUpRoute()}>Isprobaj odmah</OutboundLink>
						<Link to={aboutPremiumRoute()} className="primary">
							PREMIUM paket &rarr;
						</Link>
					</nav>
				</main>
			</AboutPackageContainer>

			<SignUpCTA mt="5rem" />
			<Footer />
		</Layout>
	);
};
